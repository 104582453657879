import React from "react";
import { graphql } from "gatsby";
import { makeStyles, p } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ContactForm from "../components/contact";
import Customers from "../components/customers";
import Layout from "../components/layout";
import Markdown from "../components/content";
import Testimonials from "../components/testimonials";
import SEO from "../components/seo";
import { scheduleDiscoveryCall } from "../utils/calendly";

const useStyles = makeStyles((theme) => ({
  section1: {
    background: "#FAF9FF",
    paddingTop: 60,
    paddingBottom: 60,
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      paddingTop: 140,
      paddingBottom: 140,
    },
    "& $buttons": {
      [theme.breakpoints.up("md")]: {
        justifyContent: "center",
      },
    },
  },
  buttons: {},
  heading: {
    color: "#293963",
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.up("sm")]: {
      fontSize: 28,
    },
  },
  a_title: {
    color: "#23325F",
    fontSize: 24,
    fontWeight: 600,
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
    },
  },
  a_summary: {
    marginTop: 24,
    color: "#354454",
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    },
  },
  c_title: {
    fontSize: 18,
    fontWeight: 500,
    color: "#293963",
    [theme.breakpoints.up("sm")]: {
      fontSize: 24,
    },
  },
  c_summary: {
    marginTop: 16,
    fontSize: 14,
    color: "#354454",
  },
  c_link: {
    fontSize: 14,
    fontWeight: 600,
    display: "inline-flex",
    alignItems: "center",
  },
  c_media: {
    objectFit: "contain",
    width: "100%",
    height: 320,
  },

  client_logo: {
    maxHeight: 40,
    maxWidth: "100%",
    display: "block",
  },
  f_title: {
    fontSize: 16,
    fontWeight: 500,
    color: "#293963",
    [theme.breakpoints.up("sm")]: {
      fontSize: 20,
    },
  },
  f_summary: {
    marginTop: 16,
    fontSize: 14,
    color: "#354454",
  },
  order2: {
    [theme.breakpoints.up("md")]: {
      order: 2,
    },
  },
}));

const Product = ({ data }) => {
  const classes = useStyles();

  const {
    seoTitle,
    seoDescription,
    heroSection,
    fold2,
    whyNanonets,
    ctaBlock,
    testimonials,
    customers,
    documentTypes,
  } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />

      <section className={classes.section1}>
        <Container>
          <Grid container>
            <Grid>
              <div>
                <p className={classes.a_title}>{heroSection.title1}</p>
                <p className={classes.a_summary}>{heroSection.title2}</p>
                <div style={{ marginTop: 40 }}>
                  <Grid container spacing={2} className={classes.buttons}>
                    <Grid item xs={12} sm={"auto"}>
                      <Button
                        color="primary"
                        size="large"
                        variant="outlined"
                        style={{ width: "100%", textTransform: "none" }}
                        onClick={() => {
                          window.open("https://app.nanonets.com", "_blank");
                        }}
                      >
                        Get started for free
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={"auto"}>
                      <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        style={{ width: "100%", textTransform: "none" }}
                        onClick={scheduleDiscoveryCall}
                      >
                        Request a demo
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section style={{ marginTop: 100, marginBottom: 100 }}>
        <Container>
          <Grid container spacing={8}>
            <Grid item xs={12} md={6} className={classes.order2}>
              <img
                src={fold2.image}
                alt=""
                style={{ maxHeight: 320, width: "100%", objectFit: "contain" }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <div>
                <p className={classes.heading}>{fold2.title}</p>
                <div style={{ marginTop: 32, color: "#354454", fontSize: 14 }}>
                  <Markdown data={fold2.summary} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section style={{ marginTop: 100, marginBottom: 100 }}>
        <Container>
          <Grid container justify="center" style={{ textAlign: "center" }}>
            <Grid item xs={12} md={10}>
              <div className={classes.heading}>{whyNanonets.title}</div>
            </Grid>
          </Grid>

          <div>
            {whyNanonets.points.map((item, index) => (
              <Grid
                key={index}
                container
                spacing={2}
                alignItems="center"
                direction={index % 2 === 0 ? "row-reverse" : ""}
                style={{ marginTop: 100 }}
              >
                <Grid item xs={12} sm>
                  <img
                    data-src={item.image}
                    className={`lazyload ${classes.c_media}`}
                    alt={item.altText}
                  />
                </Grid>

                <Grid item xs={12} sm={1}></Grid>

                <Grid item xs={12} sm>
                  <div className={classes.c_title}>{item.title}</div>
                  <div className={classes.c_summary}>{item.summary}</div>
                </Grid>
              </Grid>
            ))}
          </div>
        </Container>
      </section>

      <section>
        <Container>
          <p className={classes.heading} style={{ textAlign: "center" }}>
            {documentTypes.sectionTitle}
          </p>

          <div style={{ marginTop: 80 }}>
            <Grid container spacing={6}>
              {documentTypes.documents.map((document) => (
                <Grid key={document.title} item xs={12} sm={6} md={4}>
                  <p className={classes.f_title}>{document.title}</p>

                  <p className={classes.f_summary}>{document.summary}</p>
                </Grid>
              ))}
            </Grid>
          </div>
        </Container>
      </section>

      <section
        style={{
          background: "linear-gradient(0deg, #48559E -52.78%, #283577 90.59%)",
          color: "#fff",
          textAlign: "center",
          paddingTop: 100,
          paddingBottom: 100,
          marginTop: 100,
        }}
      >
        <Container>
          <Grid container justify="center">
            <Grid item xs={12} md={10}>
              <div>
                <p
                  className={classes.heading}
                  style={{
                    marginTop: 0,
                    color: "inherit",
                    textAlign: "center",
                  }}
                >
                  {ctaBlock.title}
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section style={{ marginTop: 100, marginBottom: 100 }}>
        <Container>
          <Grid container justify="center">
            <Grid item xs={12} md={10}>
              <div className={classes.heading} style={{ textAlign: "center" }}>
                {customers.title}
              </div>
            </Grid>
          </Grid>

          <div style={{ marginTop: 72 }}>
            <Customers />
          </div>
        </Container>
      </section>

      <section style={{ marginTop: 100, marginBottom: 320 }}>
        <Container style={{ position: "relative" }}>
          <div>
            <Testimonials data={testimonials.cards} />
          </div>
        </Container>
      </section>

      <ContactForm />
    </Layout>
  );
};

export default Product;

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        seoTitle
        seoDescription
        heroSection {
          title1
          title2
        }
        fold2 {
          title
          summary
          image
        }
        whyNanonets {
          title
          points {
            title
            summary
            image
            altText
          }
        }
        ctaBlock {
          title
        }
        testimonials {
          title
          cards {
            title1
            title2
            body
            ctaText
            link
          }
        }
        customers {
          title
          logos {
            url
          }
        }
        documentTypes {
          sectionTitle
          documents {
            title
            summary
          }
        }
      }
    }
  }
`;
