import React from "react";
import { Grid, withStyles } from "@material-ui/core";

const styles = {
  e_box: {
    border: "1px solid #f1f1f1",
    boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.04)",
    borderRadius: 8,
    height: "100%",
    overflow: "hidden",
    padding: 40,
    cursor: "pointer",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease-in",
    "&:hover": {
      backgroundColor: "#475597",
      // border: '1px solid #BDC8FF',
      boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.04)",
      "& $e_title1": {
        color: "rgba(255, 255, 255, 0.6)",
      },
      "& $e_title2": {
        color: "#fff",
      },
      "& $e_body": {
        color: "rgba(255, 255, 255, 0.6)",
      },
      "& $e_cta": {
        color: "#fff",
      },
    },
  },
  e_box_inner: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  e_image: {
    width: "100%",
    height: 120,
    objectFit: "cover",
    background: "#505D9F",
  },
  e_title1: {
    fontSize: 12,
    marginBottom: 4,
    letterSpacing: "0.12em",
    textTransform: "uppercase",
    color: "#546fff",
  },
  e_title2: {
    color: "#354454",
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: "0.02em",
    marginBottom: 22,
  },
  e_body: {
    fontSize: 14,
    color: "#354454",
    flex: 1,
  },
  e_cta: {
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: "0.04em",
    display: "flex",
    alignItems: "center",
    marginTop: 42,
    color: "#546fff",
  },
};

function Testimonials({ classes, data }) {
  return (
    <Grid container spacing={6}>
      {data.map((item, index) => (
        <Grid key={index} item xs={12} sm={6} md={4}>
          <div
            className={classes.e_box}
            // onClick={() => {
            //   window.open(item.link, "_blank");
            // }}
          >
            <div className={classes.e_box_inner}>
              <div className={classes.e_title1}>{item.title1}</div>
              <div className={classes.e_title2}>{item.title2}</div>
              <div className={classes.e_body}>{item.body}</div>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

export default withStyles(styles)(Testimonials);
